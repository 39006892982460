import React from "react"
import "./blob.css"

const Blob = props => (
  <div className="BlobGroup">
    <div className="BehindBlob">
      <svg
        width="550"
        height="550"
        viewBox="0 0 600 600"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <linearGradient id="MyGradient">
            <stop offset="5%" stop-color="#3e76f7" />
            <stop offset="95%" stop-color="#88e6f9" />
          </linearGradient>
        </defs>
        <g transform="translate(300,300)">
          <path
            d="M141.7,-123.8C178.1,-67.3,198.2,-8.8,188.5,46.6C178.7,102,139,154.2,82,188.8C24.9,223.4,-49.6,240.6,-92.2,212.1C-134.7,183.7,-145.4,109.6,-156.3,41.4C-167.3,-26.8,-178.7,-89.1,-152.7,-143.2C-126.7,-197.3,-63.3,-243.2,-5.3,-238.9C52.6,-234.6,105.3,-180.3,141.7,-123.8Z"
            fill="url(#MyGradient)"
          />
        </g>
      </svg>
    </div>
    <div className="BlobImage">
      <svg
        width="600"
        height="600"
        viewBox="0 0 600 600"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <pattern
            id="img"
            patternUnits="userSpaceOnUse"
            width={props.width + 1000}
            height={props.height + 1000}
            x={props.x}
            y={props.y}
          >
            <image
              href={props.image}
              x="0"
              y="0"
              width={props.width}
              height={props.height}
            />
          </pattern>
        </defs>
        <g transform="translate(300,300)">
          <path
            d="M118.7,-82.2C157.7,-45.8,195.9,3.8,196.1,61.6C196.3,119.4,158.7,185.4,104.2,208.8C49.6,232.1,-21.8,212.9,-81.2,180C-140.5,147,-187.7,100.3,-198.8,46.8C-209.8,-6.6,-184.6,-66.9,-145.5,-103.4C-106.3,-139.8,-53.2,-152.4,-6.6,-147.1C39.9,-141.8,79.7,-118.6,118.7,-82.2Z"
            fill="url(#img)"
          />
        </g>
      </svg>
    </div>
  </div>
)

export default Blob
