import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Card from "../components/card"
import Blob from "../components/blob"
import styled from "styled-components"

const IndexPage = () => (
  <Layout>
    <SEO title="Create Powerful Presentations, Blazing Fast" />
    <div className="Hero">
      <div className="HeroGroup">
        <h1>
          Powerful <br /> visual + content forward slides
        </h1>
        <p>
          Complete library of engaging slides to elevate your next presentation.
          Templates for Powerpoint on Windows and Mac, with Google Slides coming
          soon.
        </p>
        <Link to="/page-2/">free starter template</Link>
        <div className="Logos">
          <img src={require("../images/logo-ppt.png")} width="50" />
          <img src={require("../images/logo-sheets.png")} width="50" />
          <img src={require("../images/logo-windows.png")} width="50" />
          <img src={require("../images/logo-mac.png")} width="50" />
        </div>
      </div>
      <svg width="100%" height="172" fill="none">
        <path fill="white">
          <animate
            repeatCount="indefinite"
            fill="freeze"
            attributeName="d"
            dur="20s"
            values="M0 25.9086C277 84.5821 433 65.736 720 25.9086C934.818 -3.9019 1214.06 -5.23669 1442 8.06597C2079 45.2421 2208 63.5007 2560 25.9088V171.91L0 171.91V25.9086Z;

            M0 86.3149C316 86.315 444 159.155 884 51.1554C1324 -56.8446 1320.29 34.1214 1538 70.4063C1814 116.407 2156 188.408 2560 86.315V232.317L0 232.316V86.3149Z;

            M0 25.9086C277 84.5821 433 65.736 720 25.9086C934.818 -3.9019 1214.06 -5.23669 1442 8.06597C2079 45.2421 2208 63.5007 2560 25.9088V171.91L0 171.91V25.9086Z;"
          />
        </path>
      </svg>
    </div>
    <div className="Cards">
      <h2>Editable slides for every purpose.</h2>
      <div className="CardGroup">
        <Card
          title="Executive Summary"
          category="Business"
          support="both"
          image={require("../images/diagram.png")}
        />
        <Card
          title="Executive Summary"
          category="Business"
          support="both"
          image={require("../images/diagram.png")}
        />
        <Card
          title="Executive Summary"
          category="Business"
          support="both"
          image={require("../images/diagram.png")}
        />
        <Card
          title="Executive Summary"
          category="Business"
          support="both"
          image={require("../images/diagram.png")}
        />
        <Card
          title="Executive Summary"
          category="Business"
          support="both"
          image={require("../images/diagram.png")}
        />
        <Card
          title="Executive Summary"
          category="Business"
          support="both"
          image={require("../images/diagram.png")}
        />
      </div>
    </div>
    <SectionContainer>
      <Blob
        image="https://cdn.shapechef.com/blog/unsplash-powerpoint-slides.png"
        width={642}
        height={461}
        x={-220}
        y={-160}
      />
      <SectionContent>
        <h2>Hassle-free,</h2>
        <h3>drop-in and add your content</h3>
        <ul>
          <li>Search for the perfect slide in our online library</li>
          <li>Download and open the slide in Powerpoint</li>
          <li>Drag and drop the slide into your presentation and edit!</li>
        </ul>
        <Link to="/page-2/">
          <button>
            <span className="Strike">$50</span> $25 Yearly, Unlimited Downloads
          </button>
        </Link>
        <br />
        <span className="Footnote">
          That's less than 1 coffee{" "}
          <img
            src="https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/237/hot-beverage_2615.png"
            height="20"
          />{" "}
          each month!
        </span>
      </SectionContent>
    </SectionContainer>
  </Layout>
)

export default IndexPage

const SectionContainer = styled.div`
  max-width: 1120px;
  display: grid;
  grid-template-columns: repeat(2, auto);
  margin: 0 auto;
  justify-content: left;
  align-items: center;

  @media (max-width: 1000px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const SectionContent = styled.div`
  margin-bottom: 30px;

  h2 {
    font-size: 40px;
    font-weight: 700;
    margin: 0;
    background: linear-gradient(104deg, #3e76f7 0%, #88e6f9 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  h3 {
    font-size: 40px;
    max-width: 400px;
    font-weight: 500;
    margin: 0;
    line-height: 1;
    color: rgba(0, 0, 0, 0.75);
  }

  ul {
    margin: 25px 0 40px 0;
    list-style-type: none;
    padding-inline-start: 0;
  }

  li {
    margin: 15px 0;
  }

  button {
    padding: 14px 24px;
    font-size: 20px;
    background: rgba(62, 118, 247, 1);
    color: white;
    border: none;
    font-weight: 600;
    border-radius: 30px;
    outline: none;
    cursor: pointer;
    transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
  }

  button:hover {
    background: rgba(14, 63, 178, 1);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
    transform: translateY(-3px);
  }

  @media (max-width: 1000px) {
    text-align: center;
    h3 {
      max-width: 100%;
    }
  }
`
