import React from "react"
import "./card.css"

const Card = props => (
  <div className="Card">
    <div className="Cover">
      <img src={props.image} />
    </div>
    <h3>{props.title}</h3>
    <div className="Details">
      <p>{props.category}</p>
      {props.support === "both" && (
        <div>
          <img src={require("../images/logo-ppt.png")} width="25" />
          <img src={require("../images/logo-sheets.png")} width="25" />
        </div>
      )}
      {props.support === "ppt" && (
        <img src={require("../images/logo-ppt.png")} width="25" />
      )}
      {props.support === "sheets" && (
        <img src={require("../images/logo-sheets.png")} width="25" />
      )}
    </div>
  </div>
)

export default Card
